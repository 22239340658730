<template>
   <div class="mb-16">
      <Notification type="error" message="Error" ref="error_noti" />
      <v-img class="ma-4 elevation-2" src="~@/assets/app.png"></v-img>
      <v-card class="ma-4">
         <v-card-title>Public Key</v-card-title>
         <v-card-text>
            <div class="text-h3 text-center">
               {{ public_key }}
            </div>
         </v-card-text>
         <v-card-actions>
            <v-spacer />
            <v-btn color="secondary" @click="navigator.clipboard.writeText(public_key)"
               ><v-icon left>mdi-content-copy</v-icon>Copiar</v-btn
            >
         </v-card-actions>
      </v-card>
      <v-card class="ma-4">
         <v-card-title>
            Proyecto de Ejemplo C#
            <v-spacer />
            <v-btn color="secondary" href="/public/lib_licencias_ng.zip" download><v-icon left>mdi-download-box-outline</v-icon>Descargar</v-btn>
         </v-card-title>
      </v-card>
   </div>
</template>

<script>
export default {
   data: function () {
      return {
         public_key: null,
      };
   },
   methods: {
      copy_public_key: function () {
         navigator.clipboard.writeText(this.public_key);
      },
   },
   created: async function () {
      try {
         await this.$store.dispatch("getPublicKey");
         this.public_key = this.$store.state.public_key;
      } catch (error) {
         this.$refs.error_noti.Show(error);
      }
   },
};
</script>

<style></style>
