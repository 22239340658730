<template>
   <div>
      <Info />
   </div>
</template>

<script>
import Info from "../components/Info/Info.vue";

export default {
   components: {
      Info,
   },
};
</script>